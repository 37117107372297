import React, { useEffect, useState, Suspense, lazy } from 'react';
import './MovieList.css';
import MovieCardSkeleton from './MovieCardSkeleton';

const MovieCard = lazy(() => import('./MovieCard'));

const MovieList = ({ movies, onSelectMovie }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Simulate a loading delay
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="movie-list">
        {Array.from({ length: 10 }).map((_, index) => (
          <MovieCardSkeleton key={index} />
        ))}
      </div>
    );
  }

  return (
    <div className="movie-list">
      {/* Suspense block for lazy loading */}
      <Suspense fallback={<div>Loading...</div>}>
        {/* Notification Card */}
        <div className="notification-card">
          <h3>Our Telegram Channel Has Moved!</h3>
          <p>
            Our previous Telegram channel was deleted. Please join our new channel to stay updated with the latest content and announcements.
          </p>
          <a href="https://t.me/+5vrelzi5pOo1ZGE1" target="_blank" rel="noopener noreferrer" className="join-button">
          Join & Support Us          </a>
        </div>

        {/* Movie Cards */}
        {movies.map((movie) => (
          <MovieCard key={movie.title} movie={movie} onSelectMovie={onSelectMovie} />
        ))}
      </Suspense>
    </div>
  );
};

export default MovieList;
