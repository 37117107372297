import React, { useState } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';

const Navbar = ({ onHomeClick }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleHomeClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    onHomeClick(); // Call the onHomeClick prop to reset pagination
    setIsMobileMenuOpen(false); // Close mobile menu on link click
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="navbar">
      <Link to="/" className="navbar-brand" onClick={handleHomeClick}>
        <div className="logo">
          <span className="ullu">Series</span><span className="hub">Hub</span>
        </div>
      </Link>
      <div className={`navbar-links ${isMobileMenuOpen ? 'active' : ''}`}>
        <Link to="/" className="navbar-link" onClick={handleHomeClick}>Home</Link>
        <a href="https://t.me/indianhotwebseries_site" className="navbar-link" target="_blank" rel="noopener noreferrer">Telegram</a>
        <Link to="https://indianseries.site/display.html" className="navbar-link">Gallery</Link>
      </div>

      {/* Hamburger Icon for mobile */}
      <div className="hamburger" onClick={toggleMobileMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </nav>
  );
};

export default Navbar;
